import React from "react";
import Hero from "../Components/Hero";
import Bio from "../Components/Bio";
import YoutubeFeed from "../Components/YoutubeFeed";
import Blogs from "../Components/Blogs";
import Player from "../Components/Player";
import Footer from "../Components/Footer";
import LoadingScreen from "../Components/LoadingScreen";
import { AnimatePresence, motion } from "framer-motion";
import { useState, useEffect } from "react";
import Nav from "../Components/Nav";

const Home = () => {

    const [isLoading, setIsLoading] = useState(true);
    const [isContentVisible, setIsContentVisible] = useState(false);

    useEffect(() => {
      const timer = setTimeout(() => setIsLoading(false), 3000); // 3 seconds for example
      return () => clearTimeout(timer);
    }, []);



    return(
        <>
            <AnimatePresence onExitComplete={() => setIsContentVisible(true) } >
            {isLoading && (
            <motion.div 
            key="loader" 
            initial={{ opacity: 1 }} 
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5, ease: 'easeInOut' }}
            >
                <LoadingScreen />
            </motion.div>
            )}
        </AnimatePresence>
        {isContentVisible && (
            <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5, ease: 'easeInOut' }}
            >
                <Nav />
                <Hero />
                <Player />
                <Bio />
                <YoutubeFeed />
                {/* <Blogs /> */}
                <Footer />
            </motion.div>
            )}
        </>
    )
}

export default Home;