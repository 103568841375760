import React from "react";
import { motion } from "framer-motion";
import buhobackground1 from '../Assets/video/buhobackground.mp4'

const LoadingScreen = () => {

    return (
        <>
        <div className="backgroundsec">
            <div className="video-intro">
            <video autoPlay playsInline muted preload="auto" src={buhobackground1}></video>
            </div>
        </div>
        </>
    )
}

export default LoadingScreen;