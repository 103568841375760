import React from 'react';

const GenePlayer = () => {
    return (
        <div className="spotify-track">
            <iframe 
                className='gene-player'
                style={{borderRadius: '20px'}} 
                src="https://open.spotify.com/embed/track/1NHjIJ3nZzBWBJ24M1bYyK?utm_source=generator&theme=0" 
                width="70%" 
                height="152" 
                frameBorder="0" 
                allowFullScreen 
                allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" 
                loading="lazy"
            ></iframe>
        </div>
    );
}



export default GenePlayer;
