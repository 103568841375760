import React, { useState } from "react";
import mrlogo from '../Assets/img/mrlogo.png'
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

const Nav = () => {
    const [isOpen, setIsOpen] = useState(false);
    const toggleNavbar = () => {
        setIsOpen(!isOpen)
    };
    const closeMenu = () => {
        setIsOpen(false)
    };
    return (
        <>
            <header className='header' id='header'>
                <nav className='nav container'>
                    <Link to ='/' className='logo'><img src={mrlogo} alt="Logo" /></Link>
                    <div className={isOpen ? 'nav__menu show' : 'nav__menu'} id='nav-menu'>
                        <ul className='nav-list'>
                            <li className='nav-item'  onClick={closeMenu} style={{"listStyle": "none" }}><Link to='/'>Home</Link></li>
                            <li className='nav-item'  onClick={closeMenu} style={{"listStyle": "none" }}><HashLink to="/#musica">Música</HashLink></li>
                            <li className='nav-item'  onClick={closeMenu} style={{"listStyle": "none" }}><HashLink to ="/#bio">Biografia</HashLink></li>
                            {/* <li className='nav-item'  onClick={closeMenu} style={{"listStyle": "none" }}><Link to ="/blog">Blog</Link></li> */}
                            <li className='nav-item'  onClick={closeMenu} style={{"listStyle": "none" }}><Link to="/contacto">Contacto</Link></li>
                        </ul>
                        {/* Close Button */}
                    </div>
                    {/* Toggle button */}
                    <div className='nav-toggle' onClick={toggleNavbar}>
                    {isOpen ? <i className="ri-close-line"></i> : <i className="ri-apps-2-line"></i>}
                    </div>
                </nav>
            </header>
        </>
    )
}

export default Nav;