import React from "react";
import { Link } from "react-router-dom";
import useFetch from "../hooks/useFecth";

const BlogRend = () => {

    let{ loading, data, error} = useFetch('http://localhost:1337/api/blogs?populate=*')
    if(loading) return <p>Cargando...</p>
    if (error) return <p>Error</p>
    console.log(data);

    return(
        <div className="bg-minis">
        { data && data.data.map((blog) =>
        <Link  key={blog.attributes.slug} to={`/blog/${blog.attributes.slug}/${blog.id}`} 
            className="links-dec" >
            <div className="bg-indivs">
            <img src={`http://localhost:1337${blog.attributes.coverImg.data.attributes.url}`} />
            <h3>{blog.attributes.blogTitle}</h3>
            <span>Leer Más</span>
            </div>
        </Link>
        )}
    </div>
    )
};

export default BlogRend