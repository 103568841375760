import React from "react";
import Footer from "../Components/Footer";
import BlogRend from "../Components/BlogRend";
import emailjs from '@emailjs/browser'
import { useState } from "react";
import Nav from "../Components/Nav";
import transition from "../Components/transition";

const BlogContent = () => {
    

    const [isSending, setIsSending] = useState(false);
    const [sendSucces, setSendSucces] = useState(false);
    
    const sendEmail = (e) => {
        e.preventDefault();
        setIsSending(true);
        emailjs.sendForm('service_u4horzj', 'template_a3t74h6', e.target, 'i1ipx857XhEC_2lsb')
            .then((response) => {
                console.log('SUCCESS!', response.status, response.text);
                setSendSucces(true);
                setIsSending(false);
                // Optionally reset form fields here
            }, (error) => {
                console.log('FAILED...', error);
                setSendSucces(false);
                setIsSending(false);
            });
        }


    return(
        <>
            <Nav />
            <div className="bgh-content">
                <div className="bgh-hero">
                    <h1>Nuestro Blog</h1>
                    <div className="bgh-input">
                        <form onSubmit={sendEmail}>
                            <div>
                                <label htmlFor="Nombre">Nombre:</label>
                                <input type="text" name="nombre" />
                            </div>
                            <div>
                                <label for >Correo Electrónico:</label>
                                <input type="email" name="email_de" />
                            </div>
                            <button type="submit">¡Suscríbete y no te pierdas nada!</button>
                            {isSending && <p>Activando suscrpición...</p>}
                            {sendSucces && <p>¡Ya formas parte del universo Mr.Nova!</p>}
                        </form>
                    </div>
                </div>
                <div className="bg-grid-content">
                    <p>¡Bienvenidos y bienvenidas al <strong>blog de Mr.Nova</strong>! Un espacio pensado especialmente para conocernos un poquito mejor.

                        <br />Aquí encontrareis artículos sobre experiencias, anécdotas, e historias sobre nuestro día a día y formas de trabajo.

                        <br />Porque hacer música mola, pero contarla desde dentro, también.

                        ¡Os esperamos!
                    </p>
                    <BlogRend />
                </div>
            </div>
        <Footer />
        </>
    )
}

export default transition(BlogContent);