import React from "react";
import cd1 from '../Assets/img/cd1.png';
import HoldPlayer from "./HoldPlayer";
import GenePlayer from "./GenePlayer";
import GenePlayerMov from "./GenePlayerMov";
import MariPlayerMov from "./MariPlayerMov";
import MariPlayer from "./MariPlayer";
import HoldPlayerMov from "./HoldPlayerMov";
import { motion, useInView, useAnimation } from "framer-motion";
import { useRef } from "react";
import { useEffect } from "react";


const Player = () => {

    const ref = useRef(null)
    const isInView = useInView(ref, {once: true})

    const mainControls = useAnimation()

    useEffect(() => {
        if(isInView){
            mainControls.start('visible')
        }
    },[isInView])

    return(
        <>
        <div className="player" id='musica'>
            <motion.section 
                ref={ref}
                variants={{
                hidden: {opacity: 0, y: 75},
                visible: {opacity: 1, y: 0}
                }}
                initial='hidden'
                animate={mainControls}
                transition={{
                duration: 0.7,
                delay: 0.25
                    }}
                className="player-sec">
                <div className="big-cd">
                    <div className="bigcd-text">
                    <motion.h2
                    initial={{ x: -900 }}
                    animate={{ x: 0 }}
                    transition={{
                        duration: 1.1,
                        ease: 'easeInOut',
                        delay: 2
                    }}
                    >Últimos lanzamientos</motion.h2>
                    <motion.h3
                    initial={{ scale: 0 }}
                    animate={{ scale: 1 }}
                    transition={{
                        duration: 0.4,
                        ease: 'easeInOut',
                        delay: 3,
                        type: 'sprint',
                        bounce: 0.4
                        }}
                    
                    >DALE AL PLAY</motion.h3>
                    </div>
                    <motion.img
                     animate={{ y: [0, 20, 0] }}
                     transition={{
                        duration: 1.5,
                        ease: 'easeInOut',
                        repeat: Infinity,
                        bounce: 0.2
                     }}
                    src={cd1} 
                    alt="cd player nova" />
                </div>
                <div  style={{ overflow: 'hidden'}} className="songs-demo">
                <motion.div 
                     variants={playerIn}
                     transition={{
                         duration: 0.7,
                         ease: 'easeInOut',
                         delay: 0.6,
                         type: 'spring',
                         bounce: 0.5
                     }}                     
                    className="hon-demo">
                        < HoldPlayer />
                    </motion.div>       
                    <motion.div 
                    variants={playerIn}
                    transition={{
                        duration: 0.7,
                        ease: 'easeInOut',
                        delay: 0.9,
                        type: 'spring',
                        bounce: 0.5
                    }}
                    className="mr-demo">
                        < MariPlayer />
                    </motion.div>
                    <motion.div 
                    variants={playerIn}
                    transition={{
                        duration: 0.7,
                        ease: 'easeInOut',
                        delay: 1.2,
                        type: 'spring',
                        bounce: 0.5
                    }}   
                    className="gn-demo">
                        < GenePlayer />
                    </motion.div>
                </div> 
                <div className="songs-demov">
                <div className="hon-demo">
                        < HoldPlayerMov />
                    </div>
                    <div className="mr-demo">
                        < MariPlayerMov />
                    </div>
                    <div className="gn-demo">
                        < GenePlayerMov />
                    </div>
                </div> 
            </motion.section>
        </div>
        </>
    )
}

export default Player;


//ANIMATIONS

const playerIn = {
    hidden: {scale: 0},
    visible: {scale: 1}
}