import React from 'react';

const HoldPlayerMov = () => {
    return (
        <div className="spotify-player">
           <iframe 
           style={{borderRadius: '20px'}} 
           src="https://open.spotify.com/embed/track/0KyO93ar80Hwae33aJqcIB?utm_source=generator&theme=0" 
           width="100%" 
           height="152" 
           frameBorder="0" 
           allowfullscreen="" 
           allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" 
           loading="lazy"
           ></iframe>
        </div>
    );
}


export default HoldPlayerMov;