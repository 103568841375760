import React  from "react";
import ivan1 from '../Assets/img/ivan1.jpg'
import ivan2 from '../Assets/img/ivan2.jpg'
import pau3 from '../Assets/img/pau3.jpg'
import { useRef } from "react";
import { useAnimation, useInView } from "framer-motion";
import { useEffect } from "react";
import { motion } from "framer-motion";

const Bio = () => {

    const ref = useRef(null)
    const isInView = useInView(ref, {once: true})
    const mainControls = useAnimation()
    useEffect(() => {
        if(isInView) {
            mainControls.start('visible')
        }

    },[isInView])

    return(
        <>
        <div className="bio-container" id='bio'>
            <div
            className="bio">
                <motion.section 
                ref={ref}
                variants={{
                hidden: {opacity: 0, y: 75},
                visible: {opacity: 1, y: 0}   
                }}
                initial='hidden'
                animate={mainControls}
                transition={{
                duration: 0.7,
                delay: 0.25
                }}     
                className="bio-sections">
                    <div className="bio-text">
                        <h4>Biografia</h4>
                        <h3>Mr.Nova Desde Dentro</h3>
                        <p>Somos dos amigos de toda la vida, cuyos caminos musicales
                            se han vuelto a juntar tras una larga temporada sin tocar juntos.
                            De toda nuestra experiencia e ilusión, nace Mr.Nova, un proyecto autosuficiente
                            donde lo único que importa es la música, sus emociones, y vosotros, nuestro público.
                            ¿Os apetece un espectaculo desenfadado libre de tonterías? ¡Os esperamos!
                        </p>
                    </div>
                    <div className="bio-img">
                        <motion.img
                        whileHover={{ scale: 1.4}}
                        transition={{
                            duration: 0.6,
                            ease: 'easeInOut',
                        }} 
                        className="iv1" 
                        src={ivan1} 
                        alt="" />
                        <motion.img
                        whileHover={{ scale: 0.7}}
                        transition={{
                            duration: 0.9,
                            ease: 'easeInOut',
                        }}  
                        className="iv2" 
                        src={ivan2} 
                        alt="" />
                        <motion.img
                         whileHover={{ scale: 0.8, opacity: 0.8}}
                         transition={{
                            duration: 0.7,
                            ease: 'easeInOut',
                            type: 'spring',
                            bounce: 0.2
                            }}  
                        className="pa1" 
                        src={pau3} 
                        alt="" />
                    </div>
                </motion.section>
            </div>
        </div>
        </>
    )
}

export default Bio;