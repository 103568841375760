import './styles/App.scss';
import Home from './pages/Home';
import { BlogContent } from './pages';
import { Route, Routes } from 'react-router';
import BlogIndv from './Components/BlogIndv';
import Contacto from './pages/Contacto';
import { AnimatePresence } from 'framer-motion';


function App() {


  return (
    <div className="App">
      <AnimatePresence mode = 'wait'>
      <Routes>
        <Route path='/' element={<Home />} ></Route>
        <Route path='/blog' element={<BlogContent />} ></Route>
        <Route path='/blog/:slug/:id' element={<BlogIndv />} ></Route>
        <Route path='/contacto' element={<Contacto />} ></Route>
      </Routes>
      </AnimatePresence>
    </div>
    
  );
}

export default App;
