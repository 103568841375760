import React from "react";
import { useRef } from "react";
import { useAnimation, useInView } from "framer-motion";
import { useEffect } from "react";
import { motion } from "framer-motion";



const Blogs = () => {

    const ref = useRef(null);
    const isInView = useInView(ref, {once: true});
    const mainControls = useAnimation();

    useEffect(() => {
        if(isInView){
            mainControls.start('visible')
        }
    },[isInView])

    return(
    <>

        {/* <motion.div 
        ref={ref}
        variants={{
            hidden: {opacity: 0, y: 75},
            visible: {opacity: 1, y: 0}   
        }}
        initial='hidden'
        animate={mainControls}
        transition={{
            duration: 0.7,
            delay: 0.25
        }}           
        className="blogs-section">
            <div className="recent-blog">
                <img src={consejosblog} alt="pau levantando la mano" />
                <h4>Mejorar como productor musical: Las 5 claves</h4>
                <span>Leer Más</span>
            </div>
                <div className="subrecent-blogs">
                    <div className="subblog-titles">
                        <span>Blogs recientes</span>
                    </div>
                    <div className="subrecents-covers">
                        <div className="subr1">
                            <img src={producevocesblog} alt="pp" />
                            <div className="subtitles">
                            <h4>Producción de Voces: Técnicas Avanzadas</h4>
                            <span>Leer Más</span>
                            </div>
                        </div>
                        <div className="subr2">
                            <img src={mezclarvocesblog} alt="pp" />
                            <div className="subtitles">
                            <h4>COMO MEZCLAR VOCES PROFESIONALES: LA CADENA DE MR.NOVA</h4>
                            <span>Leer Más</span>
                            </div>
                        </div>
                        <div className="subr3">
                            <img src={Aprenderprodublog} alt='aa' />
                            <div className="subtitles">
                            <h4>COMO APRENDER PRODUCCIÓN MUSICAL: MÉTODO INFALIBLE</h4>
                            <span>Leer Más</span>
                            </div>
                        </div>
                    </div>
                </div>
        </motion.div> */}
      
    </>
    )
}

export default Blogs;