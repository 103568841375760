import React, { useEffect, useState } from "react";
import landingport2 from '../Assets/img/landingport2.png'
import { motion } from "framer-motion";
import estafascover2 from '../Assets/img/estafascover2.png';


const Hero = () => {

    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768)
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        }

    },[] )

    return(
        <>
        <div className="hero-section">
            <section className="hero">
                <div className="hero-text">
                    <motion.h2
                    initial={{ opacity: 0, scale: 0 }}
                    animate= {{ opacity: 1, scale: 1 }}
                    transition={{ duration: 1, ease: 'easeInOut' }}
                    >Tu Estafador De Confianza</motion.h2>
                    <motion.h1
                    initial={{ opacity: 0 }}
                    animate= {{ opacity: 1 }}
                    transition={{ duration: 2, ease: 'easeInOut' }}
                    >¡YA DISPONIBLE!</motion.h1>
                    <motion.a
                    initial={{ scale:0 }}
                    animate={{ scale: 1 }}
                    transition={{ 
                        duration: 0.7,
                        delay: 1,
                        ease: 'easeInOut',
                        type: "spring", 
                        bounce: 0.6 }} 
                    className="hero-btn" 
                    href="https://open.spotify.com/intl-es/track/0KyO93ar80Hwae33aJqcIB?si=7c137cfc84b34620">
                    ¡Escúchalo!</motion.a>
                </div>
                <div className="lan-img">
                    <motion.img 
                    src={estafascover2} alt="paulanding" />
                </div>
                <motion.div 
                initial={{ scale: 0 }}
                animate={{ scale: [1.8, 1] }}
                transition={{ 
                duration: 1, 
                ease: 'easeInOut',   
                }}
                className="social-icons">
                <a href="https://www.youtube.com/channel/UC9VpeG2vXeENoQfOcoH92dg" target="_blank"><i class="ri-youtube-fill"></i></a>
                <a href="https://www.instagram.com/mrnovaoficial/"><i class="ri-instagram-fill"></i></a>
                <a href="https://open.spotify.com/artist/161Wxex9nghDK9QmzKmPje?si=MTsTFfbGR-i7kYMPOAOeFw"><i class="ri-spotify-fill"></i></a>
                <a href="https://www.tiktok.com/@mrnovamusica"><i class="ri-tiktok-fill"></i></a>
                </motion.div>
            </section>
        </div>
        </>
    )
}

export default Hero;