import React from "react";
import emailjs from '@emailjs/browser'

const Footer = () => {

    
    const sendEmail = (e) => {
        e.preventDefault();
        emailjs.sendForm('service_u4horzj', 'template_a3t74h6', e.target, 'i1ipx857XhEC_2lsb')
        }


    return(
        <>
        <div className="footer-container">
            <h5>¡Únete a la Família de Mr.Nova!</h5>
            <div className="newsletter-inputs">
                <form onSubmit={sendEmail} >
                    <input 
                    type="email"
                    name='email_de'
                    placeholder="Escríbe tu Correo"
                    required
                    />
                    <button type="submit" >Suscríbete</button>
                </form>
            </div>
            <div className="rights">
                <span>© Mr.Nova Records Todos Los Derechos Reservados 2023</span>
                <div className="social-footer">
                <a href="https://www.youtube.com/channel/UC9VpeG2vXeENoQfOcoH92dg" target="_blank"><i class="ri-youtube-fill"></i></a>
                <a href="https://www.instagram.com/mrnovaoficial/"><i class="ri-instagram-fill"></i></a>
                <a href="https://open.spotify.com/artist/161Wxex9nghDK9QmzKmPje?si=MTsTFfbGR-i7kYMPOAOeFw"><i class="ri-spotify-fill"></i></a>
                <a href="https://www.tiktok.com/@mrnovamusica"><i class="ri-tiktok-fill"></i></a>
                </div>
            </div>
        </div>
        </>
    )
};

export default Footer;