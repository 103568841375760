import React from "react";
import paucontactmsg from '../Assets/img/paucontactmsg.png';
import { useState } from "react";
import emailjs from '@emailjs/browser'
import Nav from "../Components/Nav";
import transition from "../Components/transition";


const Contacto = () => {

    const [isSending, setIsSending] = useState(false);
    const [sendSucces, setSendSucces] = useState(false);


    
    const sendEmail = (e) => {
        e.preventDefault();
        setIsSending(true);
        emailjs.sendForm('service_u4horzj', 'template_cu166fe', e.target, 'i1ipx857XhEC_2lsb')
            .then((response) => {
                console.log('SUCCESS!', response.status, response.text);
                setSendSucces(true);
                setIsSending(false);
                // Optionally reset form fields here
            }, (error) => {
                console.log('FAILED...', error);
                setSendSucces(false);
                setIsSending(false);
            });
        }
 
    return(
        <>
        <Nav />
        <div className="contact-container">
            <div className="form-contact">
                <form onSubmit={sendEmail}>
                <h1>¡Contacta con nosotros!</h1>
                    <input 
                    type="text"
                    placeholder="Nombre"
                    name = 'nombre'
                    id="nombre"
                    className="nombre"
                    required
                    />
                    <input 
                    type="email"
                    placeholder="Email"
                    name = 'email_de'
                    id="emailDe"
                    className="email_de"
                    required
                    />
                    <textarea 
                    type="text"
                    placeholder="Mensaje"
                    rows='4'
                    name = 'message'
                    id="message"
                    className="message_box"
                    required
                    />
                    <button type="submit" >Enviar</button>
                    {isSending && <p>Enviando...</p>}
                    {sendSucces && <p>¡Gracias por contactar con nosotros!</p>}
                </form>
            </div>
            <div className="pau-contact">
                <img src={paucontactmsg} alt="paucontact" />
            </div>
        </div>
        
        </>
    )
}

export default transition(Contacto);