import { useAnimation, useInView } from "framer-motion";
import React, { useEffect, useRef } from "react";
import { motion } from "framer-motion";

const YoutubeFeed = () => {

    const ref = useRef(null)
    const isInView = useInView(ref, {once: true})
    const mainControls = useAnimation()
    useEffect(() => {
        if(isInView) {
            mainControls.start('visible')
        }

    },[isInView])

    return(
        <>
        <div className="youtube-container">
            <motion.section 
                ref={ref}
                variants={{
                hidden: {opacity: 0, y: 75},
                visible: {opacity: 1, y: 0}   
                }}
                initial='hidden'
                animate={mainControls}
                transition={{
                duration: 0.7,
                delay: 0.25
                }}       
            className="youtube-feed">
                <div className="youtube-text">
                    <h2>Nuestros Videos</h2>
                    <h3>Youtube Feed</h3>
                    <iframe
                        className="yt1" 
                        width="980" 
                        height="615" 
                        src="https://www.youtube.com/embed/RgI1IDMR84g?si=-UDxsKsTj8Hn22Y9" 
                        title="YouTube video player" 
                        frameBorder="0" 
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                        allowFullScreen
                    ></iframe>
                </div>
                <div className="youtube3">
                        <iframe
                        className="yt2" 
                        width="360" 
                        height="200" 
                        src="https://www.youtube.com/embed/0rbLYiOheiA?si=zHrPu3kCt00F4kBz" 
                        title="YouTube video player" 
                        frameBorder="0" 
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                        allowFullScreen
                         ></iframe>
                        <iframe
                        className="yt2"  
                        width="360" 
                        height="200" 
                        src="https://www.youtube.com/embed/4g0bPMw8ZXA?si=lEOMRskKglvuiuce" 
                        title="YouTube video player" 
                        frameBorder="0" 
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                        allowFullScreen
                         ></iframe>
                        <iframe
                        className="yt2"  
                        width="360" 
                        height="200" 
                        src="https://www.youtube.com/embed/RgI1IDMR84g?si=-UDxsKsTj8Hn22Y9" 
                        title="YouTube video player" 
                        frameBorder="0" 
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                        allowFullScreen
                        ></iframe>
                </div>
            </motion.section>
        </div>
        </>
    )
}

export default YoutubeFeed;