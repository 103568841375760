import React from "react";
import pausphere2 from '../Assets/img/pausphere2.png'
import { useParams } from "react-router";
import Footer from "./Footer";
import useFetch from "../hooks/useFecth";
import ReactMarkdown from 'react-markdown';
import Nav from "./Nav";

const BlogIndv = ({blogs}) => {

    const { id } = useParams();

    const { loading, data, error } = useFetch(`http://localhost:1337/api/blogs/${id}`);

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error.message}</p>;



    let blog = data && data.data ? data.data : null
    console.log(data)
    

    return(
        <>
        <Nav />
        <section className="blog-content">
            <div className="blog-hero">
                <div className="video-display">
                <iframe
                width="1060" 
                height="515" 
                src={blog.attributes.ytEmbed}
                 frameborder="0" 
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                allowfullscreen
                >            
                </iframe>
                </div>
                <div className="hero-titles">
                    <h1>{blog.attributes.blogTitle}</h1>
                    <div className="author-title">
                        <img src={pausphere2} alt="pau author" />
                        <span>Pau Sáez</span>
                    </div>
                </div>
            </div>
            <div className="blog-context">
                <ReactMarkdown className='line-break' >{blog.attributes.blogContent}</ReactMarkdown>
                <div className="our-socials">
                <h5>¡No olvides seguirnos!</h5>
                <ul class="wrapper">
                    <li class="icon instagram">
                        <span class="tooltip">Instagram</span>
                        <a href="https://www.instagram.com/mrnovaoficial/" target="_blank" rel="noreferrer"><span><i class="ri-instagram-fill"></i></span></a>
                    </li>
                    <li class="icon tiktok">
                        <span class="tooltip">TikTok</span>
                        <a href="https://www.tiktok.com/@mrnovamusica" target="_blank" rel="noreferrer"><span><i class="ri-tiktok-fill"></i></span></a>
                    </li>
                    <li class="icon spotify">
                        <span class="tooltip">Spotify</span>
                        <a href="https://open.spotify.com/artist/161Wxex9nghDK9QmzKmPje?si=MTsTFfbGR-i7kYMPOAOeFw" target="_blank" rel="noreferrer"><span><i class="ri-spotify-fill"></i></span></a>
                    </li>
                    <li class="icon youtube">
                        <span class="tooltip">Youtube</span>
                        <a href="https://www.youtube.com/channel/UC9VpeG2vXeENoQfOcoH92dg" target="_blank" rel="noreferrer" ><span><i class="ri-youtube-fill"></i></span></a>
                    </li>
                </ul>
            </div>
            </div>
        </section>
        <Footer />
        </>
    )
}

export default BlogIndv;